import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Paragraph, Image, List, Icon, ScrollButton, Span } from 'View/Common';
import { RoundedBox } from 'View/Component';
//Todo: Importe nacharbeiten, wenn Comps ausgetauscht wurden
import { Headline, Section, Row, Col, Link, Button, Card } from '@missionme/mime_elements';
import Style from './TrustPortalPage.style';
import { Menu } from 'View/Component';
import { colors } from 'View/Theme';
import {
  descriptionImage,
  euSign,
  isoSign,
  preventionSign,
  symbolChat,
  symbolDart,
  symbolLock,
  symbolShield,
  symbolDownload,
  isoCertificate,
} from 'img/trustPortal';
import { HeaderSection } from 'View/Container/HeaderSection/HeaderSection';
import { MixedCooperations } from 'View/Component/CooperationSlider/MixedCooperations';

export const TrustPortalPage: FC<RouteComponentProps> = () => {
  const handleOnOpenMail = () => {
    const a = document.createElement('a');
    a.href = 'mailto:security@7schlaefer.app';
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Menu />
      <Style>
        <HeaderSection>
          <Headline level={2}>Guter Schlaf beginnt mit Sicherheit und Vertrauen </Headline>
          <Paragraph>So setzen wir bei 7Schläfer auf Sicherheit, Compliance und Datenschutz</Paragraph>
          <Button href={'mailto:security@7schlaefer.app'}>Frage stellen</Button>
        </HeaderSection>

        <Section className="signet-section text-center" verticalPadding="small">
          <RoundedBox>
            <Headline level={2}>Geprüfte Sicherheit und Wirksamkeit</Headline>
            <Row>
              <Col md="4" className="signet-box">
                <Image src={preventionSign} alt="Deutscher Standard Präventionen - Prüfsiegel" />
                <Paragraph>Zertifiziert und anerkannt von gesetzlichen Krankenkassen</Paragraph>
              </Col>
              <Col md="4" className="signet-box">
                <Image src={euSign} alt="EU Datenschutz - Prüfsiegel" />
                <Paragraph>Wir agieren im Sinne der DSGVO</Paragraph>
              </Col>
              <Col md="4" className="signet-box">
                <Image src={isoSign} alt="ISO/IEC27001 Zertifiziert - Prüfsiegel" />
                <Paragraph>
                  <Link color={'beige'} href={'https://www.missionme.de'}>
                    MissionMe
                  </Link>{' '}
                  ist zertifiziert nach ISO 27001:2022
                </Paragraph>
                <Link color={'beige'} href={isoCertificate}>
                  <Span className="small-bold">
                    <Image src={symbolDownload} className="download-icon" />
                    ISO Zertifikat herunterladen
                  </Span>
                </Link>
              </Col>
            </Row>
          </RoundedBox>
        </Section>

        <Section className="description-section" verticalPadding="small">
          <div className="description-image-wrapper">
            <Image src={descriptionImage} className="description-image" />
            <div>
              <Headline level={2}>Diese Themen stehen bei uns im Fokus</Headline>
              <List icon={<Image src={symbolLock} className="list-icon" />} className="main-list">
                {[<Headline level={3}>Vertraulichkeit im Umgang mit sensiblen Daten</Headline>]}
              </List>
              <List
                className="description-list"
                icon={<Icon color={colors.sleep.beige.c100} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
              >
                <Paragraph className="image-row image-row-1">
                  <strong>Schutz der Vertraulichkeit:</strong> Weitergabe von Informationen an Dritte wird vermieden und passiert ansonsten
                  nur nach Konsent-Abfragen an unsere Nutzer (DSGVO).
                </Paragraph>
                <Paragraph className="image-row image-row-2">
                  <strong>Zugangskontrolle:</strong> Nutzer erhalten nur Zugriff auf die Ressourcen, zu denen sie berechtigt sind. Die
                  Berechtigungen werden streng kontrolliert und regelmäßig überprüft.
                </Paragraph>
                <Paragraph>
                  <strong>Sicherung der Integrität:</strong> Vorkehrungen werden getroffen, um zu verhindern, dass Informationen unbefugt
                  verändert werden.
                </Paragraph>
                <Paragraph>
                  <strong>Schutz der IT-Vermögenswerte:</strong> Alle Computer, mobilen Geräte, Netzwerkausrüstungen, Software und sensiblen
                  Daten werden vor internen, externen, absichtlichen oder zufälligen Bedrohungen geschützt. Dies mindert die Risiken von
                  Diebstahl, Verlust, Missbrauch und Schaden.
                </Paragraph>
              </List>
            </div>
          </div>

          <List icon={<Image src={symbolDart} className="list-icon" />} className="main-list">
            {[<Headline level={3}>Erfüllung regulatorischer Vorgaben</Headline>]}
          </List>
          <List
            className="description-list"
            icon={<Icon color={colors.sleep.beige.c100} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
          >
            {[
              <Paragraph>
                <strong>Einhaltung gesetzlicher Anforderungen: </strong>Alle nationalen gesetzlichen und regulatorischen Anforderungen sowie
                Standards und Best Practices werden eingehalten und, wenn möglich, übertroffen.
              </Paragraph>,
            ]}
          </List>

          <List icon={<Image src={symbolShield} className="list-icon" />} className="main-list">
            {[<Headline level={3}>Geschäftspraxis</Headline>]}
          </List>
          <List
            className="description-list"
            icon={<Icon color={colors.sleep.beige.c100} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
          >
            <Paragraph>
              <strong>Aufrechterhaltung der Verfügbarkeit:</strong> Es wird gewährleistet, dass autorisierte Personen bei Bedarf auf die
              Informationen zugreifen können, um die Geschäftsprozesse aufrecht zu erhalten.
            </Paragraph>
            <Paragraph>
              <strong>Entwicklung und Pflege von Geschäftskontinuitätsplänen:</strong> Diese Pläne sollen sicherstellen, dass das
              Unternehmen trotz aller Hindernisse weiterhin arbeiten kann.
            </Paragraph>
          </List>

          <List icon={<Image src={symbolChat} className="list-icon" />} className="main-list">
            {[<Headline level={3}>Umgang im Unternehmen</Headline>]}
          </List>
          <List
            className="description-list"
            icon={<Icon color={colors.sleep.beige.c100} name="Checkmark" size={{ sm: 32, md: 32, lg: 40 }} />}
          >
            <Paragraph>
              <strong>Sensibilisierung und Schulung der Mitarbeiter:</strong> Alle Mitarbeiter werden regelmäßig in Informationssicherheit
              geschult und sensibilisiert. Die Einhaltung der Sicherheitsanforderungen ist Teil der Unternehmenskultur.
            </Paragraph>
            <Paragraph>
              <strong>Schutz der Mitarbeiter bei der Meldung von Sicherheitsbedenken:</strong> Mitarbeiter werden ermutigt,
              Sicherheitsbedenken zu melden, ohne negative Konsequenzen zu befürchten.
            </Paragraph>
          </List>
        </Section>

        <MixedCooperations id="cooperations-slider" />

        <Section className="contact-section">
          <Row>
            <Col md={6} className="left-col">
              <Headline level={2}>Du hast Fragen?</Headline>
              <Paragraph>
                Wir sind da und bereit, deine Fragen zu beantworten. Solltest du Sicherheitsbedenken haben oder Detailfragen zu unserem
                Vorgehen, zögere nicht uns zu kontaktieren.
              </Paragraph>
              <Paragraph>
                Wenn du eine Sicherheitslücke entdeckst, bitten wir dich, so zu handeln, dass die Daten unserer Nutzer geschützt werden:
              </Paragraph>
              <List>
                <Paragraph>Informiere uns so schnell wie möglich.</Paragraph>
                <Paragraph>Teste mit gefälschten Daten und Konten, nicht mit privaten Daten unserer Nutzer.</Paragraph>
                <Paragraph>Arbeite mit uns zusammen, um die Schwachstelle zu schließen, bevor du sie an andere weitergibst.</Paragraph>
              </List>
              <Paragraph>
                Wenn du ein Sicherheitsproblem findest, kontaktiere uns bitte mit entsprechenden Details, einschließlich Schritten zur
                Reproduktion oder einem Proof-of-Concept.
              </Paragraph>
            </Col>
            <Col md={6}>
              <RoundedBox className="contact-card">
                <Headline level={3}>Kontakt</Headline>
                <Paragraph>Bei Fragen oder wenn du einen Sicherheitsvorfall melden willst, kontaktiere bitte </Paragraph>
                <Link color="beige" href="mailto:security@7schlaefer.app">
                  <Paragraph className="bold">security@7schlaefer.app</Paragraph>
                </Link>
                <Paragraph>Vielen Dank für deine Hilfe. Wir werden uns umgehend mit dir in Verbindung setzen.</Paragraph>
                <Button onClick={handleOnOpenMail}>Sicherheitsvorfall melden</Button>
              </RoundedBox>
            </Col>
          </Row>
        </Section>
      </Style>
    </>
  );
};
