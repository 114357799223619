import React from 'react';
import StoreButtons from 'components/StoreButtons';

const Section_4 = () => {
  return (
    <div className="wrapper">
      <section className="home__section-4">
        <h2 className="heading heading-2">App jetzt herunterladen!</h2>
        <StoreButtons />
        <div className="wrapper__flex-row margin-top-50">
          <div className="home__image-phone home__image-phone--homescreen">
            <span className="visuallyhidden">
              Bild: iPhone 7Schläfer App Homescreen
            </span>
          </div>
          <div className="home__image-phone home__image-phone--abendroutine">
            <span className="visuallyhidden">
              Bild: iPhone 7Schläfer App Abendroutine
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section_4;
