import { useEffect } from 'react';
import { globalHistory } from '@reach/router';

export const ScrollToTop = () => {
  useEffect(() => {
    const handleScroll = () => {
      setTimeout(() => {
        const id = window.location?.hash?.replace('#', '');
        if (id) {
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
          }
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }, 100);
    };

    const unlisten = globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        handleScroll();
      }
    });

    handleScroll();

    return () => {
      unlisten();
    };
  }, []);

  return null;
};
