import React, { forwardRef, FunctionComponent, useState } from 'react';
import { Col, Image, Row } from 'View/Common';
import {
  AccessibilityModal,
  HealthInsuranceFormCooperation,
  HealthInsuranceSelectionBox,
  HealthRequirementsModal,
  IHealthInsuranceEntry,
  Section,
} from 'View/Component';
import ZppShipBackground from 'img/ZppShipBackground';
import { MarkdownModal } from 'View/Component/Modals/MarkdownModal/MarkdownModal';
import { StaticPageName } from 'api/graphql';
import { HealthInsuranceFormNoCooperation } from 'View/Component';

interface IProps {
  ref?: React.LegacyRef<HTMLElement>;
}
export const RefundCheckFormSection: FunctionComponent<IProps> = forwardRef((_, ref) => {
  const [healthInsurance, setHealthInsurance] = useState<IHealthInsuranceEntry | null>(null);
  const [showHealthRequirements, setShowHealthRequirements] = useState<boolean>(false);
  const [showAccessibilityModal, setShowAccessibilityModal] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

  return (
    <>
      <HealthRequirementsModal open={showHealthRequirements} onClose={() => setShowHealthRequirements(false)} />
      <AccessibilityModal open={showAccessibilityModal} onClose={() => setShowAccessibilityModal(false)} />
      <MarkdownModal open={showTerms} onClose={() => setShowTerms(false)} pageName={StaticPageName.TermsAndConditions} />
      <MarkdownModal open={showPrivacy} onClose={() => setShowPrivacy(false)} pageName={StaticPageName.PrivacyPolicy} />
      <Section className="refund-check-form-section" ref={ref}>
        <Image
          className="app-section-background"
          image={ZppShipBackground.src}
          srcSet={ZppShipBackground.srcSet}
          alt="Papierboot mit Angelleine"
        />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12} className="form-area">
            <HealthInsuranceSelectionBox healthInsurance={healthInsurance} onChange={setHealthInsurance} />
          </Col>
          <Col
            xs={24}
            sm={12}
            md={healthInsurance?.cooperationPartner ? { span: 11, offset: 1 } : { span: 9, offset: 3 }}
            className="price-area"
          >
            <div className="flex">
              {healthInsurance?.cooperationPartner ? (
                <HealthInsuranceFormCooperation healthInsurance={healthInsurance} setShowAccessibilityModal={setShowAccessibilityModal} />
              ) : (
                <HealthInsuranceFormNoCooperation
                  setShowHealthRequirements={setShowHealthRequirements}
                  setShowAccessibilityModal={setShowAccessibilityModal}
                />
              )}
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
});
