import { ScrollButton } from '@missionme/mime_elements';
import { RouteComponentProps } from '@reach/router';
import { CoopImage1, CoopImage2, CoopImage3 } from 'img/b2b/CoopLandingPage';
import { CoopSuccessImage, CoopLogo, CoopMobileTeaser } from 'img/b2b/ImagesAokBayern';
import React, { FC } from 'react';
import { COOPERATION_VOUCHER } from 'utils/Constants/CooperationsVoucherClients';
import { FormField, Headline, Icon, Image, List, Paragraph } from 'View/Common';
import { ImageParagraph, ImageTextRow } from 'View/Component';
import { InsuranceContainer, InsuranceLandingPage, Section } from 'View/Container';

export const CoopAokBayernPage: FC<RouteComponentProps> = () => {
  const isMinTablet = window.matchMedia('(min-width: 768px)').matches;

  return (
    <InsuranceLandingPage>
      <InsuranceLandingPage.HeaderSection brandLogo={<Image src={CoopLogo} alt="AOK Bayern Logo" />}>
        <Headline level={2}>Erholsamen Schlaf finden. Mit der {isMinTablet && <br />} AOK Bayern zur richtigen Schlafstrategie.</Headline>
        <Paragraph>
          Starte jetzt den Kurs "Erholsamen Schlaf finden" und erlebe begleitend die gesamte Einschlaf-Welt der 7Schläfer App.
        </Paragraph>
        <ScrollButton to="form-section" offset={-50}>
          Jetzt starten
        </ScrollButton>
      </InsuranceLandingPage.HeaderSection>
      <InsuranceLandingPage.CheckMarkSection image={<Image src={CoopMobileTeaser} />}>
        <Headline level={2}>Entdecke, wie guter Schlaf dein Leben verändert.</Headline>
        <Paragraph>
          7Schläfer bietet Unterstützung beim Ein-, Aus- und Durchschlafen. Die App basiert auf den neuesten Erkenntnissen der Wissenschaft.
          Versicherte der AOK Bayern erwartet im 7-wöchigen Präventionskurs ein Programm, in dem der Schlafforscher und Biologe Dr. Albrecht
          Vorster die Teilnehmenden zu Schlafexperten in eigener Sache macht. Du erfährst, wie du erholsamen Schlaf mit gesunden
          Gewohnheiten und wirksamen Routinen förderst. Die kurzen Einheiten sind einfach verständlich und du kannst die Tipps und Tricks
          sofort in deinem Alltag umsetzen.
        </Paragraph>
        <List icon={<Icon color={'var(--sleep-red-c500)'} name="CheckmarkCircle" size={27} />}>
          {[
            '7 Wochen Schlaf-Kurs zur Verbesserung deines Schlafes',
            'Begleitende Trainings und Quizze, um das Gelernte im Alltag zu verankern',
            'Kurze, einfach verständliche Einheiten für zuhause oder unterwegs - mit nur 10 Minuten am Tag zu besserem Schlaf',
          ]}
        </List>
      </InsuranceLandingPage.CheckMarkSection>
      <Section className="paragraph-section" verticalPadding="medium">
        <Headline level={2} className="text-center">
          Für Versicherte der AOK Bayern ist der <br /> Präventionskurs kostenlos.
        </Headline>
        <ImageTextRow>
          <ImageParagraph dataCyName="paragraph-image-1" image={CoopImage1}>
            Registriere dich einfach unten auf dieser Website. Beachte: Du musst 100% des Kurses absolvieren und darfst dein
            Präventionsbudget in diesem Jahr noch nicht ausgeschöpft haben.
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-2" image={CoopImage2}>
            Lade dir anschließend die 7Schläfer-App herunter und melde dich mit deiner E-Mail-Adresse an. Dein kostenloser Zugriff auf den
            Kurs "Erholsamen Schlaf finden" gilt für 12 Monate.
          </ImageParagraph>
          <ImageParagraph dataCyName="paragraph-image-3" image={CoopImage3}>
            Absolviere den Kurs vollständig innerhalb von 6 Monaten und erhalte dein Teilnahmezertifikat als Nachweis für die AOK Bayern -
            als Bonus bleibt dein App-Zugriff auf die gesamte 7Schläfer Schlaf-Welt für weitere 6 Monate bestehen.
          </ImageParagraph>
        </ImageTextRow>
      </Section>
      <Section className="form-section" id="form-section">
        <InsuranceContainer cooperationVoucher={COOPERATION_VOUCHER.AOK_BAYERN}>
          <InsuranceContainer.FormView className="text-center">
            <Headline level={2}>
              Registrierung für Versicherte der <br /> AOK Bayern
            </Headline>
            <Paragraph>
              Registriere dich hier mit deiner AOK Bayern-Versichertennummer und schalte den Kurs "Erholsamen Schlaf finden" frei. Bei
              Fragen wende dich gerne an{' '}
              <a className="mail-link" href="mailto:partner@7schlaefer.app">
                partner@7schlaefer.app
              </a>
              .
            </Paragraph>
            <InsuranceContainer.Form insuranceName="AOK Bayern">
              {({ register }) => (
                <>
                  <FormField {...register('name', { required: true })} />
                  <FormField {...register('email', { required: true })} />
                  <FormField {...register('insuranceNumber', { required: true })} />
                  <Paragraph className="form-insurance-number-subtext">
                    Deine AOK Bayern-Versichertennummer findest du auf deiner Krankenkassenkarte.
                  </Paragraph>
                  <FormField {...register('dateOfBirth', { required: true })} />
                  <FormField {...register('age')} />
                  <FormField {...register('gender')} />
                  <FormField {...register('healthPreconditions', { required: true })} />
                  <FormField {...register('legalConditions', { required: true })} />
                  <FormField {...register('dataTransfer', { required: true })} />
                  <Paragraph className="form-opt-out-notice">
                    Als 7Schläfer-Nutzer:in erhältst du Tipps, Angebote und Umfragen rund um 7Schläfer per E-Mail. Du hast jederzeit die
                    Möglichkeit, über einen Link am Ende der E-Mail zu widersprechen.
                  </Paragraph>
                </>
              )}
            </InsuranceContainer.Form>
          </InsuranceContainer.FormView>
          <InsuranceContainer.OtpView />
          <InsuranceContainer.SuccessView>
            <Image src={CoopSuccessImage} alt="Erholsamen Schlaf finden" />
          </InsuranceContainer.SuccessView>
        </InsuranceContainer>
      </Section>
    </InsuranceLandingPage>
  );
};
